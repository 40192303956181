const scrollBeforeLoad = (scrollTo, scrollingElementId) => {
  if (scrollTo) {
    const scrollingElement = scrollingElementId ? document.getElementById(scrollingElementId) : document.scrollingElement;
    scrollingElement.scrollTo(0, scrollTo);
    preventTurboOverridingScroll();
  }
};
const preventTurboOverridingScroll = () => {
  if (Turbo.navigator.currentVisit) {
    Turbo.navigator.currentVisit.scrolled = true;
  }
};
export default scrollBeforeLoad;