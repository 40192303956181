class TurboEventDecorator {
  constructor(event) {
    this.event = event;
  }
  isSuccessfulNonRedirect() {
    if (!this._fetchResponse) return false;
    return this._fetchResponse.succeeded && !this._fetchResponse.redirected;
  }
  get _fetchResponse() {
    return this.event.detail.fetchResponse;
  }
}
export default TurboEventDecorator;