export class QuerySanitizer {
  static #emptyString = "";
  static #filePathSeparatorRegex = /\//g;
  static #parentDirRegex = /\.{2}/g;
  #query;
  constructor(query) {
    this.#query = query;
  }
  toString() {
    return this.#query.replaceAll(QuerySanitizer.#parentDirRegex, QuerySanitizer.#emptyString).replaceAll(QuerySanitizer.#filePathSeparatorRegex, QuerySanitizer.#emptyString);
  }
}