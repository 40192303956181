const viewports = require("./viewports");
import { hideElement, showElement, toggleElementHidden, isInViewport, isVisible, makeElementVisible, makeElementInvisible } from "./element_visibility";
import { enableElement, disableElement } from "./element_ability";
import { ajax } from "./ajax";
import { ajaxUpload } from "./ajax_upload";
import { sortByRegExp } from "./sort_by_regexp";
import logEvent from "./activity_logger";
import { isHttps } from "./is_https";
import isCachedPreview from "./is_cached_preview";
const Helpers = {
  showElement,
  hideElement,
  toggleElementHidden,
  isInViewport,
  isVisible,
  enableElement,
  disableElement,
  ajax,
  ajaxUpload,
  makeElementVisible,
  makeElementInvisible,
  sortByRegExp,
  viewports,
  logEvent,
  isHttps,
  isCachedPreview
};
export default Helpers;