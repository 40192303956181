const appendParam = (element, key, value) => {
  const target = element.closest("a, form");
  let urlAttribute = "href";
  if (target.tagName === "FORM") {
    urlAttribute = "action";
  }
  const url = target.getAttribute(urlAttribute);
  const updatedUrl = updateQueryStringParameter(url, key, value);
  target.setAttribute(urlAttribute, updatedUrl);
};

// http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
function updateQueryStringParameter(uri, key, value) {
  let anchor = "";
  const match = uri.match(/(.+)(#\w+)/);
  if (match) {
    uri = match[1];
    anchor = match[2];
  }
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value + anchor;
  }
}
export default appendParam;