const hideElement = element => {
  element.classList.add("hidden");
};
const showElement = element => {
  element.classList.remove("hidden");
};
const toggleElementHidden = element => {
  element.classList.toggle("hidden");
};
const makeElementVisible = element => {
  element.classList.remove("invisible");
  element.classList.add("visible");
};
const makeElementInvisible = element => {
  element.classList.remove("visible");
  element.classList.add("invisible");
};
const isVisible = element => {
  return element.offsetParent !== null || element.offsetWidth > 0 && element.offsetHeight > 0;
};
const isInViewport = element => {
  const boundingClientRect = element.getBoundingClientRect();
  return boundingClientRect.top >= 0 && boundingClientRect.left >= 0 && boundingClientRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && boundingClientRect.right <= (window.innerWidth || document.documentElement.clientWidth);
};
export { hideElement, showElement, toggleElementHidden, isInViewport, isVisible, makeElementVisible, makeElementInvisible };