import { throttle } from "throttle-debounce"

class window.Utils
  @delay: (ms, func) ->
    setTimeout func, ms

  @throttle: (ms, func) ->
    if typeof ms == "function"
      func = ms
      ms = 250
    throttle ms, func

  # https://github.com/rails/rails/issues/29546
  @submit: (form) ->
    if form.getAttribute("data-remote")
      Rails.fire(form, "submit")
    else
      form.submit()
