const ajax = (url, {
  method,
  headers = {},
  data,
  signal
} = {}) => {
  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: Object.assign(getDefaultHeaders(), headers),
    credentials: "same-origin",
    signal: signal
  }).catch(error => {
    if (error.name != "AbortError") throw error;
  });
};
function getDefaultHeaders() {
  return {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "X-CSRF-Token": Rails.csrfToken()
  };
}
export { ajax };