const buildBody = (file, data) => {
  let formData = new FormData();
  formData.append("file", file);
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return formData;
};
const ajaxUpload = (url, file, data = {}) => {
  return fetch(url, {
    method: "POST",
    body: buildBody(file, data)
  });
};
export { ajaxUpload };