const commentAddedEvent = new CustomEvent("commentAdded", {
  bubbles: true
});
const openEmojiPickerEvent = onEmojiSelect => {
  return new CustomEvent("openEmojiPicker", {
    bubbles: true,
    detail: {
      onEmojiSelect: onEmojiSelect
    }
  });
};
const modalClosedEvent = returnTo => {
  return new CustomEvent("modalClosed", {
    bubbles: true,
    detail: {
      returnTo: returnTo
    }
  });
};
const editorPreviousEvent = new CustomEvent("editor:previous", {
  bubbles: true
});
const editorNextEvent = new CustomEvent("editor:next", {
  bubbles: true
});
const recordDeleteEvent = canDeleteLastRemaining => new CustomEvent("record:delete", {
  bubbles: true,
  detail: {
    canDeleteLastRemaining
  }
});
const recordAppendEvent = record => new CustomEvent("record:append", {
  bubbles: true,
  detail: {
    record: record
  }
});
const sliderStartedEvent = new CustomEvent("sliderStarted", {
  bubbles: true,
  detail: {
    logName: "scroll"
  }
});
const sliderReachedLastSlideEvent = new CustomEvent("sliderReachedLastSlide", {
  bubbles: true,
  detail: {
    logName: "scroll_all"
  }
});
const fileUploadedEvent = result => {
  return new CustomEvent("fileUploaded", {
    bubbles: true,
    detail: result
  });
};
const originLocationSelected = (locationId, locationName) => {
  return new CustomEvent("originLocationSelected", {
    detail: {
      locationId,
      locationName
    }
  });
};
const showBookmarkReminderEvent = new CustomEvent("showBookmarkReminder", {
  bubbles: true
});
const showCooksnapReminderEvent = new CustomEvent("showCooksnapReminder", {
  bubbles: true
});
const paymentAuthenticationRequiredEvent = new CustomEvent("paymentAuthenticationRequired", {
  bubbles: true
});
const stripeErrorEvent = ({
  message,
  error
}) => {
  return new CustomEvent("stripeError", {
    bubbles: true,
    detail: {
      error: error,
      message: message
    }
  });
};
const paymentMethodCreationStartedEvent = new CustomEvent("paymentMethodCreationStarted", {
  bubbles: true
});
const paymentMethodCreationCompletedEvent = new CustomEvent("paymentMethodCreationCompleted", {
  bubbles: true
});
const formEnabledEvent = new CustomEvent("formEnabled", {
  bubbles: true
});
const formDisabledEvent = new CustomEvent("formDisabled", {
  bubbles: true
});
const paymentSubmissionSuccess = new CustomEvent("paymentSubmissionSuccess", {
  bubbles: true
});
const paymentSubmissionError = detail => {
  return new CustomEvent("paymentSubmissionError", {
    bubbles: true,
    detail: detail
  });
};
const autocompleteSelectedEvent = new CustomEvent("autocompleteSelected", {
  bubbles: true
});
const elementScrolledOutEvent = new CustomEvent("elementScrolledOut", {
  bubbles: true
});
const elementScrolledInEvent = new CustomEvent("elementScrolledIn", {
  bubbles: true
});
const popoverLoadedEvent = new CustomEvent("popoverLoaded", {
  bubbles: true
});
const showPopoverEvent = new CustomEvent("showPopover", {
  bubbles: true,
  detail: {
    logName: "show"
  }
});
const hidePopoverEvent = new CustomEvent("hidePopover", {
  bubbles: true,
  detail: {
    logName: "delete"
  }
});
const revealCollapsedContentEvent = new CustomEvent("revealCollapsedContent", {
  bubbles: true
});
const becomesVisibleEvent = new CustomEvent("becomesVisible", {
  bubbles: true
});
const becomesInvisibleEvent = new CustomEvent("becomesInvisible", {
  bubbles: true
});
const elementToggledEvent = toggleId => {
  return new CustomEvent("elementToggled", {
    detail: {
      toggleId: toggleId
    }
  });
};
const autocompleteSuggestionsShownEvent = new CustomEvent("autocompleteSuggestionsShown", {
  bubbles: true
});
const autocompleteSuggestionsHiddenEvent = new CustomEvent("autocompleteSuggestionsHidden", {
  bubbles: true
});
const Events = {
  paymentMethodCreationStarted: paymentMethodCreationStartedEvent,
  paymentMethodCreationCompleted: paymentMethodCreationCompletedEvent,
  paymentSubmissionSuccess: paymentSubmissionSuccess,
  paymentSubmissionError: paymentSubmissionError,
  formEnabled: formEnabledEvent,
  formDisabled: formDisabledEvent,
  commentAdded: commentAddedEvent,
  openEmojiPicker: openEmojiPickerEvent,
  modalClosed: modalClosedEvent,
  editorNext: editorNextEvent,
  editorPrevious: editorPreviousEvent,
  recordDelete: recordDeleteEvent,
  sliderStarted: sliderStartedEvent,
  sliderReachedLastSlide: sliderReachedLastSlideEvent,
  fileUploaded: fileUploadedEvent,
  originLocationSelected,
  paymentAuthenticationRequired: paymentAuthenticationRequiredEvent,
  recordAppend: recordAppendEvent,
  showBookmarkReminder: showBookmarkReminderEvent,
  showCooksnapReminder: showCooksnapReminderEvent,
  stripeError: stripeErrorEvent,
  autocompleteSelected: autocompleteSelectedEvent,
  elementScrolledOut: elementScrolledOutEvent,
  elementScrolledIn: elementScrolledInEvent,
  popoverLoaded: popoverLoadedEvent,
  showPopover: showPopoverEvent,
  hidePopover: hidePopoverEvent,
  revealCollapsedContent: revealCollapsedContentEvent,
  becomesVisible: becomesVisibleEvent,
  becomesInvisible: becomesInvisibleEvent,
  elementToggledEvent,
  autocompleteSuggestionsShown: autocompleteSuggestionsShownEvent,
  autocompleteSuggestionsHidden: autocompleteSuggestionsHiddenEvent
};
export default Events;